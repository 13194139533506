<template>
  <div class="content-wrapper">
    <div class="expense-statistics main-content">

      <div class="page-header">
        <h1>Staff Expense Statistics</h1>
      </div>

      <div class="chart-wrapper">
        <BarChart :options="chartOptions" />
      </div>

      <div class="staff-expense-statistics-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Staff ID</th>
              <th scope="col">Staff Name</th>
              <th scope="col">Position</th>
              <th scope="col">Department</th>
              <th scope="col">Expense Count</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="employee in employees">
              <td>{{ employee.staff_id }}</td>
              <td>{{ employee.employee_name }}</td>
              <td>{{ employee.position }}</td>
              <td>{{ employee.department }}</td>
              <td>{{ employee.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BarChart from '@/components/chart/BarChart.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'StaffExpenseStatistics',
  components: {
    BarChart,
  },
  data(){
    return {
      employees: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted(){
    this.loadAllExpenses();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async loadAllExpenses(){
      const loginInfo = Common.getLoginInfo();
      try{
        const expenses = await Financial.loadAllExpense(this.apiUrl, loginInfo);
        //For chart
        const employeesLabel = [];
        const employeeIds = [];
        const expensesCounts = [];

        //For table
        const expensesTableDatas = [];

        //Get Emplyoee which had expense record
        for(let i = 0; i < expenses.length; i++){
          const expense = expenses[i];

          if(!employeesLabel.includes(expense.employee_name)){
            const employee = {
              'employee_name': expense.employee_name,
              'employee_id': expense.employee_id,
              'staff_id': expense.staff_id,
              'position': expense.position,
              'department': expense.department,
              'count': 0,
            };
            expensesTableDatas.push(employee);
            employeesLabel.push(expense.employee_name);
          }

          if(!employeeIds.includes(expense.employee_id)){
            employeeIds.push(expense.employee_id);
          }
        }

        //Count employee's expenses
        for(let i = 0; i < employeeIds.length; i++){
          const employeeId = employeeIds[i];

          const employeeExpenses = expenses.filter(function(expense){
            if(employeeId === expense.employee_id){
              return expense;
            }
          });

          const expensesCount = employeeExpenses.length;
          expensesCounts.push(expensesCount);

          //Get count to table data
          const expensesTableObject = expensesTableDatas.find(function(employee){
            if(employee.employee_id === employeeId){
              return employee;
            }
          });
          expensesTableObject['count'] = expensesCount;
        }

        this.employees = expensesTableDatas;

        const chartData = {
          labels: employeesLabel,
          datasets: [
            {
              label: 'Count of employee\'s expense',
              backgroundColor: '#343A40',
              data: expensesCounts,
            }
          ],
        };
        this.setCurrentChartData(chartData);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentChartData']),
  },
  computed: mapState({
   currentChartData: state => state.chart.currentChartData,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.staff-expense-statistics-table{
  margin-top: 50px;
}
</style>
