<template>
  <div class="report-wrapper">
    <div class="report-header">
      <h1 class="page-header">Admin Report</h1>
      <el-form ref="report-select-form" :model="form">
        <el-form-item label="Select Report" style="max-width: 550px;">
          <el-select v-model="form.report" placeholder="Select the report">
            <el-option label="HR Allocation Overview" value="HR Allocation Overview"></el-option>
            <el-option label="Staff Expense Statistics" value="Staff Expense Statistics"></el-option>
            <el-option label="Expense Type Statistics" value="Expense Type Statistics"></el-option>
            <el-option label="Payment Statistics" value="Payment Statistics"></el-option>
            <el-option label="Profit Loss Report" value="Profit Loss Report"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <HrAllocation v-if="form.report === 'HR Allocation Overview'"/>
    <StaffExpenseStatistics v-if="form.report === 'Staff Expense Statistics'"/>
    <ExpenseTypeStatistics v-if="form.report === 'Expense Type Statistics'" />
    <PaymentStatistics v-if="form.report === 'Payment Statistics'" />
    <ProfitLossReport v-if="form.report === 'Profit Loss Report'" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import HrAllocation from '@/views/work/HrAllocation.vue';
import StaffExpenseStatistics from '@/views/financial/StaffExpenseStatistics.vue';
import ExpenseTypeStatistics from '@/views/financial/ExpenseTypeStatistics.vue';
import PaymentStatistics from '@/views/financial/PaymentStatistics.vue';
import ProfitLossReport from '@/views/financial/ProfitLossReport.vue';

export default {
  name: 'AdminReport',
  components: {
    HrAllocation,
    StaffExpenseStatistics,
    ExpenseTypeStatistics,
    PaymentStatistics,
    ProfitLossReport,
  },
  data(){
    return {
      form: {
        'report': 'HR Allocation Overview'
      },
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.report-wrapper{
  .page-header{
    h1{
      font-size: 1.4em;
    }
  }
  .report-header{
    margin-left: 350px;

    h1{
      margin-top: 50px;
    }
  }
}

</style>
